(function($){
	function checkSidebarState() {
		let $sidebar = $('.et_social_sidebar_networks');
		let $button = $('.et_social_hide_sidebar');

		if ($sidebar.length > 0) {
			if ($sidebar.hasClass('et_social_visible_sidebar')) {
				$button.prop('ariaExpanded', true);
				$('.et_social_share').prop('tabindex', '0');
				$( '.et_social_hide_sidebar').removeClass( 'fa-share-alt' );
				$( '.et_social_hide_sidebar').addClass( 'fa-close' );
			} else {
				$button.prop('ariaExpanded', false);
				$('.et_social_share').prop('tabindex', '-1');
				$( '.et_social_hide_sidebar').removeClass( 'fa-close' );
				$( '.et_social_hide_sidebar').addClass( 'fa-share-alt' );
			}
		}
	}
	document.querySelectorAll('.et_social_hide_sidebar').forEach(button => {
		button.addEventListener('keydown', function(event) {
			if (event.key === 'Enter' || event.key === ' ') {
				$(this).click();
			}
		});
	});
	$( function () {
		$( 'body' ).on( 'click', '.et_social_share', function() {
			var $this_el = $(this),
				social_type = $this_el.data( 'social_type' ),
				share_link = 'media' == social_type ? $this_el.data( 'social_link' ) : $this_el.prop( 'href' );

			var left = ( $( window ).width()/2 ) - ( 550/2 );
			var top = ( $( window ).height()/2 ) - ( 450/2 );
			var new_window = window.open( share_link, '', 'scrollbars=1, height=450, width=550, left=' + left + ', top=' + top );

			if ( window.focus ) {
				new_window.focus();
			}

			return false;
		});

		function get_url_parameter( param_name ) {
			var page_url = window.location.search.substring(1);
			var url_variables = page_url.split('&');
			for ( var i = 0; i < url_variables.length; i++ ) {
					var curr_param_name = url_variables[i].split( '=' );
				if ( curr_param_name[0] == param_name ) {
					return curr_param_name[1];
				}
			}
		}

		function setCookieExpire(days) {

			var ms = days*24*60*60*1000;

			var date = new Date();
			date.setTime(date.getTime() + ms);

			return "; expires=" + date.toUTCString();
		}

		function checkCookieValue(cookieName, value) {
			return parseCookies()[cookieName] === value;
		}

		function parseCookies() {
			var cookies = document.cookie.split('; ');

			var ret = {};
			for (var i = cookies.length - 1; i >= 0; i--) {
			  var el = cookies[i].split('=');
			  ret[el[0]] = el[1];
			}
			return ret;
		}

		function set_cookie( $expire ) {
			cookieExpire = setCookieExpire( $expire );
			document.cookie = 'etSocialCookie=true' + cookieExpire;
		}

		//separate function for the setTimeout to make it work properly within the loop.
		function make_popup_visible( $popup, $delay ){
			setTimeout( function() {
				$popup.addClass( 'et_social_visible et_social_animated' );
				if ( $( '.et_social_resize' ).length ) {
					$( '.et_social_resize' ).each( function() {
						define_popup_position( $( this ) );
					});
				}
			}, $delay );
		}

		function auto_popup( this_el, delay ) {
			var $current_popup_auto = this_el;
			if ( ! $current_popup_auto.hasClass( 'et_social_animated' ) ) {
				var $cookies_expire_auto = $current_popup_auto.data( 'cookie_duration' ) ? $current_popup_auto.data( 'cookie_duration' ) : false,
					$delay = delay;

				if ( ( false !== $cookies_expire_auto && ! checkCookieValue( 'etSocialCookie', 'true' ) ) || false == $cookies_expire_auto ) {

					make_popup_visible ( $current_popup_auto, $delay );

					if ( false !== $cookies_expire_auto ) {
						set_cookie( $cookies_expire_auto );
					}
				}
			}
		}

		function define_popup_position( $this_popup ) {
			setTimeout( function() { // make sure all css transitions are finished to calculate the heights correctly
				var this_popup = $this_popup,
					networks_div = this_popup.find( '.et_social_networks' ),
					header_height = this_popup.find( '.et_social_header' ).outerHeight() || 0,
					total_count_height = this_popup.find( '.et_social_totalcount' ).height(),
					extra_height = 0 < total_count_height ? 20 : 0;

				this_popup.height( this_popup.find( '.et_social_icons_container' ).innerHeight() + header_height + total_count_height + 20 + extra_height );

				var	popup_max_height = this_popup.hasClass( 'et_social_popup_content' ) ? $( window ).height() : $( window ).height() - 60;

				if ( this_popup.hasClass( 'et_social_popup_content' ) && 768 < $( window ).width() ) {
					popup_max_height = popup_max_height - 50;
				}

				this_popup.css( { 'max-height' : popup_max_height + 'px' } );

				if( this_popup.hasClass( 'et_social_popup_content' ) ) {
					var top_position = $( window ).height() / 2 - this_popup.innerHeight() / 2;
					this_popup.css( { 'top' : top_position + 'px' } );
				}

				var networks_div_height = this_popup.height() - header_height + total_count_height - extra_height;
				networks_div.height( networks_div_height );
			}, 400 );
		}

		$( window ).on( 'resize', () => {
			if ( $( '.et_social_resize' ).length ) {
				$( '.et_social_resize' ).each( function() {
					define_popup_position( $( this ) );
				});
			}
		});

		$( '.et_social_hide_sidebar' ).on('click', function(){
			$( '.et_social_hide_sidebar' ).toggleClass( 'et_social_hidden_sidebar' );
			$( '.et_social_sidebar_networks' ).toggleClass( 'et_social_hidden_sidebar et_social_visible_sidebar' );
			checkSidebarState();
		});
	});

	$(function(){
		$(".et_social_sidebar_networks").bind("focusout", function (e) {
			if (e.currentTarget.contains(e.relatedTarget)) {
			} else {
				$('.et_social_sidebar_networks').removeClass('et_social_visible_sidebar');
				$('.et_social_sidebar_networks').addClass('et_social_hidden_sidebar');
				$('.et_social_hide_sidebar').prop('ariaExpanded', false);
				$('.et_social_share').prop('tabindex', '-1');
				$( '.et_social_hide_sidebar').removeClass( 'fa-close' );
				$( '.et_social_hide_sidebar').addClass( 'fa-share-alt' );
			}
		});
	});

	$(document).keydown(function(e){
        if(e.keyCode == 27) {
            if ($('.et_social_sidebar_networks').hasClass('et_social_visible_sidebar')) {
                $('.et_social_sidebar_networks').removeClass('et_social_visible_sidebar');
				$('.et_social_sidebar_networks').addClass('et_social_hidden_sidebar');
				$('.et_social_hide_sidebar').prop('ariaExpanded', false);
				$('.et_social_share').prop('tabindex', '-1');
				$( '.et_social_hide_sidebar').removeClass( 'fa-close' );
				$( '.et_social_hide_sidebar').addClass( 'fa-share-alt' );
				$('.et_social_hide_sidebar').focus();
            } 
        } 
    });

})(jQuery)
